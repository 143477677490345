
// @ngInject
function stateConfig($stateProvider) {
  $stateProvider.state('app.login', {
    url: '/login',
    controller: 'LoginController',
    data: { pageTitle: 'Login' },

  });
}

// @ngInject
function LoginController($location, AuthService) {
  if (!AuthService.hasToken()) {
    AuthService.showLogin();
  } else {
    $location.path('/');
  }
}

export default angular
  .module('app.login', [])
  .config(stateConfig)
  .controller('LoginController', LoginController)
  .name;
