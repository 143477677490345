import angular from 'angular';
import pickingList from './pickinglist/pickingListPrintView';
import './pickinglist/pickingListPrintView.less';

// @ngInject
function stateConfig($stateProvider) {
  $stateProvider.state('app.print', {
    url: '/print',
    abstract: true,
    views: {
      '@': {
        controller: 'PrintController as vm',
      },
    },
    resolve: {
      user: userResolve,
    },
  });
}

// @ngInject
function userResolve(AuthService) {
  return AuthService.getUser();
}

// @ngInject
function PrintController(AuthService, user) {
  /* jshint validthis: true */
  const vm = this;
  vm.hasClaim = AuthService.hasClaim;
  vm.user = user;
}

export default angular
  .module('app.print', [
    pickingList,
  ])
  .controller('PrintController', PrintController)
  .config(stateConfig)
  .name;
