
// @ngInject
function MetadataApiService(DS, DSHttpAdapter, $q) {
  return {
    getStores,
  };

  function getStores() {
    const deferred = $q.defer();
    DSHttpAdapter.GET(`${DS.defaults.baseUrl}metadata/stores`).then((response) => {
      deferred.resolve(response.data);
    }, (error) => {
      deferred.reject(error);
    });
    return deferred.promise;
  }
}

export default angular
  .module('resources.metadata', [])
  .factory('MetadataApiService', MetadataApiService)
  .name;
