// @ngInject
function LoadSpinnerService($rootScope) {
  const service = {
    start,
    stop,
  };
  return service;

  function start() {
    $rootScope.$emit('loadSpinner-start');
  }

  function stop() {
    $rootScope.$emit('loadSpinner-stop');
  }
}

// @ngInject
function loadSpinner(usSpinnerService, $rootScope) {
  return {
    templateUrl: require('./loadSpinner.tpl.html'),
    restrict: 'EA',
    replace: true,
    scope: { spinnerName: '@' },
    link($scope) {
      $scope.loaders = 0;

      $rootScope.$on('loadSpinner-start', (event, data) => {
        if ($scope.loaders === 0) {
          usSpinnerService.spin($scope.spinnerName);
          $scope.isLoading = true;
        }
        $scope.loaders++;
      });

      $rootScope.$on('loadSpinner-stop', (event, data) => {
        $scope.loaders--;

        if ($scope.loaders === 0) {
          usSpinnerService.stop($scope.spinnerName);
          $scope.isLoading = false;
        }
      });
    },
  };
}

export default angular
  .module('directives.loadspinner', [])
  .service('LoadSpinnerService', LoadSpinnerService)
  .directive('loadSpinner', loadSpinner)
  .name;
