
// @ngInject
function initializeErrorLogging(ErrorLogService) {
  if (process.env.RAYGUN_API_KEY !== '') {
    ErrorLogService.init({
      apiKey: process.env.RAYGUN_API_KEY,
      version: process.env.FLOW_VERSION,
    });
  }
}

// @ngInject
function initializeAuthService(AuthService) {
  AuthService.setToken();
}

// @ngInject
function setRootScopeVariables($rootScope, $state, $stateParams) {
  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;
}

function eventSubscribers($rootScope, $state) {
  $rootScope.$on('$stateChangeStart', (e, toState, toParams, fromState, fromParams) => {
    e.preventDefault();
    $rootScope.toState = toState;
    $rootScope.toParams = toParams;

    $state.go(toState.name, toParams, { notify: false })
      .then(() => {
        if (angular.isDefined(toState.data.pageTitle)) {
          $rootScope.pageTitle = toState.data.pageTitle;
        }
        $rootScope.$broadcast('$stateChangeSuccess', toState, toParams, fromState, fromParams);
      }, (error) => $rootScope.$broadcast('$stateChangeError', toState, toParams, fromState, fromParams, error));
  });
}

export default angular
  .module('app.core.init', [])
  .run(initializeErrorLogging)
  .run(initializeAuthService)
  .run(setRootScopeVariables)
  .run(eventSubscribers)
  .name;
