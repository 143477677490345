// @ngInject
export default function OrderFilterController($scope) {
  const ctrl = this;
  ctrl.stadiumOrderNumber = '';
  ctrl.orderNumber = '';
  ctrl.toDate = undefined;
  ctrl.fromDate = undefined;

  $scope.fromDatePopup = {
    opened: false,
  };
  $scope.toDatePopup = {
    opened: false,
  };
  $scope.fromDateOptions = {
    minDate: undefined,
    maxDate: new Date(),
    startingDay: 1,
  };
  $scope.toDateOptions = {
    minDate: new Date(),
    maxDate: new Date(),
    startingDay: 1,
  };

  ctrl.onOpenFromDate = onOpenFromDate;
  ctrl.onOpenToDate = onOpenToDate;
  ctrl.onFormSubmit = onFormSubmit;
  ctrl.onFromDateChanged = onFromDateChanged;

  function onOpenFromDate() {
    if (!ctrl.fromDate) {
      ctrl.fromDate = new Date();
    }

    $scope.fromDatePopup.opened = true;
  }

  function onOpenToDate() {
    if (!ctrl.toDate) {
      ctrl.toDate = new Date();
    }

    $scope.toDateOptions.minDate = ctrl.fromDate;
    $scope.toDatePopup.opened = true;
  }

  function onFormSubmit() {
    ctrl.onSearch({
      $params: {
        orderNumber: ctrl.orderNumber,
        stadiumOrderNumber: ctrl.stadiumOrderNumber,
        fromDate: getFormatedFromDate(ctrl.fromDate),
        toDate: getFormatedToDate(ctrl.toDate),
      },
    });
  }

  function onFromDateChanged() {
    if (!ctrl.fromDate) {
      ctrl.toDate = undefined;
    }
  }

  function getFormatedFromDate(date) {
    if (!date) {
      return undefined;
    }

    date.setHours(0, 0, 0, 0);
    return date.toJSON();
  }

  function getFormatedToDate(date) {
    if (!date) {
      return undefined;
    }

    date.setHours(23, 59, 59, 999);
    return date.toJSON();
  }
}
