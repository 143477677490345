
// @ngInject
function ProductionItemsApiService(DS, DSHttpAdapter, $q) {
  let state = {};

  return {
    getState,
    clearState,
    getAll,
    getByStadiumReference,
    updateAnnotation,
    updatePrintStatus,
    updateStadiumReference,
    setArchived,
    resetDeliveryStatus,
  };

  function getState() {
    return state;
  }

  function clearState() {
    state = {};
  }

  function setState(sorting, pageNumber, pageSize, printStatus, deliveryStatus, stadiumReference, bamatexReference, storeId) {
    state.sorting = sorting;
    state.pageNumber = pageNumber;
    state.pageSize = pageSize;
    state.printStatus = printStatus;
    state.deliveryStatus = deliveryStatus;
    state.stadiumReference = stadiumReference;
    state.bamatexReference = bamatexReference;
    state.storeId = storeId;
  }

  function getAll(sorting, pageNumber, pageSize, printStatus, deliveryStatus, stadiumReference, bamatexReference, storeId) {
    setState(sorting, pageNumber, pageSize, printStatus, deliveryStatus, stadiumReference, bamatexReference, storeId);
    const deferred = $q.defer();
    const params = {
      printStatus,
    };

    if (angular.isDefined(pageNumber) && pageNumber > 1) {
      params.pageNumber = pageNumber;
    }
    if (angular.isDefined(pageSize) && pageSize !== 20) {
      params.pageSize = pageSize;
    }

    addFilterParams(params, {
      stadiumReference,
      bamatexReference,
      storeId,
      deliveryStatus,
    });

    if (!_.isEmpty(sorting)) {
      for (const obj in sorting) {
        const sortBy = obj;
        const sortDirection = sorting[obj];

        if (sortBy !== 'id' || sortDirection !== 'asc') {
          params.sortBy = sortBy;
          params.sortDirection = sortDirection;
        }
      }
    }

    DSHttpAdapter.GET(`${DS.defaults.baseUrl}productionItems`, { params }).then((response) => {
      deferred.resolve(response.data);
    }, (error) => {
      deferred.reject(error);
    });
    return deferred.promise;
  }

  function addFilterParams(params, filterParams) {
    for (const paramName in filterParams) {
      const paramValue = filterParams[paramName];
      if (paramValue && paramValue.length > 0) {
        params[paramName] = paramValue;
      }
    }
  }

  function getByStadiumReference(stadiumReference) {
    const deferred = $q.defer();
    DSHttpAdapter.GET(`${DS.defaults.baseUrl}productionItems/${stadiumReference}`).then((response) => {
      deferred.resolve(response.data);
    }, (error) => {
      deferred.reject(error);
    });
    return deferred.promise;
  }

  function updateAnnotation(stadiumReference, annotation) {
    const deferred = $q.defer();
    DSHttpAdapter.POST(`${DS.defaults.baseUrl}annotation/${stadiumReference}`, { annotation }).then((response) => {
      deferred.resolve(response.data);
    }, (error) => {
      deferred.reject(error);
    });
    return deferred.promise;
  }

  function updateStadiumReference(stadiumReference, newReference) {
    const deferred = $q.defer();
    DSHttpAdapter.POST(`${DS.defaults.baseUrl}stadiumreference/${stadiumReference}`, { stadiumReference: newReference }).then((response) => {
      deferred.resolve(response.data);
    }, (error) => {
      deferred.reject(error);
    });
    return deferred.promise;
  }

  function setArchived(stadiumReference) {
    const deferred = $q.defer();
    DSHttpAdapter.POST(`${DS.defaults.baseUrl}archive/${stadiumReference}`).then((response) => {
      deferred.resolve(response.data);
    }, (error) => {
      deferred.reject(error);
    });
    return deferred.promise;
  }

  function resetDeliveryStatus(stadiumReference) {
    const deferred = $q.defer();
    DSHttpAdapter.POST(`${DS.defaults.baseUrl}productionItems/${stadiumReference}/reset`)
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((error) => {
        deferred.reject(error);
      });

    return deferred.promise;
  }

  function updatePrintStatus(stadiumReferences) {
    return DSHttpAdapter.POST(`${DS.defaults.baseUrl}print`, { stadiumReferences });
  }
}

export default angular
  .module('resources.productionItems', [])
  .factory('ProductionItemsApiService', ProductionItemsApiService)
  .name;
