// @ngInject
function UsersApiService(DS, DSHttpAdapter) {
  return {
    getSelf,
  };

  function getSelf() {
    return DSHttpAdapter.GET(`${DS.defaults.baseUrl}user`)
      .then((response) => response.data);
  }
}

export default angular
  .module('resources.users', [])
  .factory('UsersApiService', UsersApiService)
  .name;
