import angular from 'angular';
import productionItems from './productionItems/productionItems';
import pickingQueue from './pickingQueue/pickingQueue';
import printingQueue from './printingQueue/printingQueue';
import problemQueue from './problemQueue/problemQueue';
import users from './users/users';
import metadata from './metadata/metadata';
import pickingList from './pickingList/pickingList';
import orderStatus from './orderStatus/orderStatus';
import OrderDetailsApiModule from './orderDetails/OrderDetailsApiModule';
import archiveQueue from './archiveQueue/archiveQueue';
import ProblemApiModule from './problem/ProblemApiModule';
import PrintingSummaryApiModule from './printingSummary/PrintingSummaryApiModule';


export default angular
  .module('resources', [
    productionItems,
    pickingQueue,
    printingQueue,
    problemQueue,
    users,
    metadata,
    pickingList,
    orderStatus,
    OrderDetailsApiModule,
    archiveQueue,
    ProblemApiModule,
    PrintingSummaryApiModule,
  ])
  .name;
