export default function generatePagesArray(currentPage, totalItems, pageSize) {
  let maxPage;
  let maxPivotPages;
  let minPage;

  const maxBlocks = 17;
  const numPages = Math.ceil(totalItems / pageSize);
  const pages = [];

  if (numPages > 1) {
    pages.push({
      type: 'prev',
      number: Math.max(1, currentPage - 1),
      active: currentPage > 1,
    });
    pages.push({
      type: 'first',
      number: 1,
      active: currentPage > 1,
      current: currentPage === 1,
    });
    maxPivotPages = Math.round((maxBlocks - 5) / 2);
    minPage = Math.max(2, currentPage - maxPivotPages);
    maxPage = Math.min(numPages - 1, currentPage + maxPivotPages * 2 - (currentPage - minPage));
    minPage = Math.max(2, minPage - (maxPivotPages * 2 - (maxPage - minPage)));
    let i = minPage;
    while (i <= maxPage) {
      if ((i === minPage && i !== 2) || (i === maxPage && i !== numPages - 1)) {
        pages.push({
          type: 'more',
          active: false,
        });
      } else {
        pages.push({
          type: 'page',
          number: i,
          active: currentPage !== i,
          current: currentPage === i,
        });
      }
      i += 1;
    }
    pages.push({
      type: 'last',
      number: numPages,
      active: currentPage !== numPages,
      current: currentPage === numPages,
    });
    pages.push({
      type: 'next',
      number: Math.min(numPages, currentPage + 1),
      active: currentPage < numPages,
    });
  }
  return pages;
}
