import _ from 'lodash';

// @ngInject
function authService($rootScope, $state, $uibModal, $cookies, $q, $window, DSHttpAdapter, DS, UsersApiService, ErrorLogService) {
  let _token;
  let _user = null;
  let _isAuthenticated = false;
  let _isAuthenticating = false;
  let _loginDialog = null;

  const service = {
    isAuthenticated,
    isAuthenticating,
    getUser,
    hasClaim,
    setToken,
    getCurrentToken,
    hasToken,
    showLogin,
    closeLogin,
    logIn,
    logOut,
    expireSession,
  };

  return service;

  function isAuthenticated() {
    return _isAuthenticated;
  }

  function isAuthenticating() {
    return _isAuthenticating;
  }

  function hasClaim(claim) {
    const claims = angular.isDefined(_user) && _user != null ? _user.claims : null;
    const result = _.intersection(claims || [], [claim]).length === 1;
    return result;
  }

  function getUser() {
    const _userPromise = $q.defer();

    // If we already have the user, just return it
    if (angular.isDefined(_user) && _user != null) {
      _userPromise.resolve(_user);
      return _userPromise.promise;
    }
    UsersApiService.getSelf().then((user) => {
      _user = user;
      _isAuthenticated = true;
      ErrorLogService.setUser(_user);
      _userPromise.resolve(_user);
    }, (x) => {
      _userPromise.reject(x);
    });
    return _userPromise.promise;
  }

  function getToken(email, password) {
    const data = {
      email,
      password,
    };
    return DSHttpAdapter.POST(`${DS.defaults.baseUrl}auth`, data);
  }

  function setToken(token) {
    if (token) {
      $cookies.put('flow-token', token);
    } else {
      token = $cookies.get('flow-token');
    }
    if (token) {
      _token = token;
    }
  }

  function getCurrentToken() {
    return _token;
  }

  function showLogin(message) {
    if (_loginDialog == null) {
      _loginDialog = $uibModal.open({
        templateUrl: require('../loginDialog/loginDialog.tpl.html'),
        controller: 'LoginDialogController as vm',
        size: 'sm',
        backdrop: (isLoginState()) ? 'static' : true,
        keyboard: !(isLoginState()),
        resolve: {
          message() {
            return message;
          },
        },
      });

      _loginDialog.result.then(onCloseLoginSuccess, onCloseLoginFail);
    }
  }

  function closeLogin() {
    closeDialog(false);
  }

  function closeDialog(success) {
    if (success) {
      _loginDialog.close();
    } else {
      _loginDialog.dismiss('cancel');
    }
  }

  function onCloseLoginSuccess() {
    if (isLoginState()) {
      // Go to the stated if it exists, else go to root url
      if ($rootScope.toState != null && $rootScope.toState.name != 'login') {
        $state.go($rootScope.toState.name, $rootScope.toParams, { replace: true });
      } else {
        $state.go('app.main.start');
      }
    }

    _loginDialog = null;
  }

  function onCloseLoginFail() {
    _loginDialog = null;
  }

  function logIn(email, password) {
    if (!_isAuthenticating) {
      _isAuthenticating = true;
      const dfd = $q.defer();

      // Request a token with username & password
      getToken(email, password).then((response) => {
        _isAuthenticating = false;
        setToken(response.data.token);

        closeDialog(true);

        // Resolve the promise
        dfd.resolve(true);

        // If request for token fails, reject the promise
      }, (error) => {
        _isAuthenticating = false;
        dfd.reject(error);
      });

      return dfd.promise;
    }
  }

  function logOut() {
    DSHttpAdapter.DEL(`${DS.defaults.baseUrl}auth`).then(() => {
      expireSession();
      $window.location.href = '/';
    });
  }

  function hasToken() {
    return angular.isDefined(_token) && _token != null;
  }

  function expireSession() {
    _token = null;
    _user = null;
    _isAuthenticated = false;
    // Remove the cookie
    $cookies.remove('flow-token');
    ErrorLogService.setUser(null);
  }

  function isLoginState() {
    if ($rootScope.$state.current.abstract || $rootScope.$state.current.name == 'login') {
      return true;
    }
    return false;
  }
}

export default angular
  .module('services.security.authService', [])
  .factory('AuthService', authService)
  .name;
