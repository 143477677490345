import jsData from 'js-data-angular';
import angularFilter from 'angular-filter';
import 'angular-spinner';
import ngCookies from 'angular-cookies';
import ngMessages from 'angular-messages';
import 'ng-table';
import ngTranslate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import uiRouter from '@uirouter/angularjs';
import uiSelect from 'ui-select';
import 'js-data-http';
import directives from '../../common/directives/directives';
import services from '../../common/services/services';
import resources from '../../common/resources/resources';
import config from './config/config';
import init from './init/init';


export default angular
  .module('app.core', [
    jsData,
    angularFilter,
    'angularSpinner',
    ngCookies,
    ngMessages,
    'ngTable',
    ngTranslate,
    uiBootstrap,
    uiRouter,
    uiSelect,
    directives,
    services,
    resources,
    config,
    init,
  ])
  .name;
