
function HelperService() {
  this.getDisplayName = function (key, value) {
    let result = key != null ? key : value;
    if (key != null && value != null) {
      result = `${key} - ${value}`;
    }
    return result == null ? '' : result;
  };

  this.getChangedData = function (original, edited, values) {
    return this.findDiff(_.pick(original, values), edited);
  };

  this.findDiff = function (original, edited) {
    const diff = {};
    for (const key in original) {
      if (original[key] !== edited[key]) {
        diff[key] = edited[key];
      }
    }
    return diff;
  };

  this.createArrayDiffs = function (oldArray, newArray, sameId) {
    const ArrayDiffEntry = (function () {
      function ArrayDiffEntry(position, oldValue, newValue) {
        this.position = position;
        this.oldValue = oldValue;
        this.newValue = newValue;
      }

      ArrayDiffEntry.prototype.changeType = function () {
        if (angular.isUndefined(this.oldValue)) {
          return 0;
        }
        if (angular.isUndefined(this.newValue)) {
          return 1;
        }
      };

      return ArrayDiffEntry;
    }());

    if (angular.isUndefined(sameId)) {
      sameId = angular.equals;
    } else if (_.isString(sameId)) {
      const idFieldName = sameId;
      sameId = function (o1, o2) {
        return o1[idFieldName] === o2[idFieldName];
      };
    }

    const arrayDiffs = [];

    function arrayIndexOf(array, element, index) {
      for (let i = index; i < array.length; i++) {
        if (sameId(array[i], element)) {
          return i;
        }
      }
      return -1;
    }

    const oldArrayCopy = oldArray ? oldArray.slice() : [];

    let index = 0;
    let i;
    for (i = 0; i < newArray.length; i++) {
      const newValue = newArray[i];
      // If we are at end of old array, just add it
      if (oldArrayCopy.length <= index) {
        arrayDiffs.push(new ArrayDiffEntry(index, undefined, newValue));
      } else {
        var done;
        do {
          done = true;
          const oldValue = oldArrayCopy[index];
          if (!sameId(oldValue, newValue)) {
            // Id mismatch, try to find the old index of the new value
            const oldIndexOfNewValue = arrayIndexOf(oldArrayCopy, newValue, index);

            if (oldIndexOfNewValue !== -1) {
              // We have found an index, lets check if there is a new index for the old value
              let newIndexOfOldValue = arrayIndexOf(newArray, oldValue, index);

              if (newIndexOfOldValue === -1) {
                // The old value doesn't exist in the new array, delete it
                arrayDiffs.push(new ArrayDiffEntry(index, oldValue, undefined));
                oldArrayCopy.splice(index, 1);
                done = false;
              } else if (newIndexOfOldValue > oldIndexOfNewValue) {
                // The new index for the old value is bigger than the old index of the new value
                if (oldArrayCopy.length <= newIndexOfOldValue) {
                  // Place the old value last
                  newIndexOfOldValue = oldArrayCopy.length - 1;
                }
                // Delete the old value
                arrayDiffs.push(new ArrayDiffEntry(index, oldValue, undefined));
                oldArrayCopy.splice(index, 1);

                // Insert the old value at new position
                arrayDiffs.push(new ArrayDiffEntry(newIndexOfOldValue, undefined, oldValue));
                oldArrayCopy.splice(newIndexOfOldValue, 0, oldValue);
                done = false;
              } else {
                // The new index for the old value is not bigger
                // Delete the old placement of the new value
                arrayDiffs.push(new ArrayDiffEntry(oldIndexOfNewValue, newValue, undefined));
                oldArrayCopy.splice(oldIndexOfNewValue, 1);

                // Insert the the new value at the current index
                arrayDiffs.push(new ArrayDiffEntry(index, undefined, newValue));
                oldArrayCopy.splice(index, 0, newValue);
              }
            } else {
              // No index was found, add it at the current index
              oldArrayCopy.splice(index, 0, newValue);
              arrayDiffs.push(new ArrayDiffEntry(index, undefined, newValue));
            }
          }
        } while (!done);
      }
      index++;
    }
    for (i = oldArrayCopy.length; i > index;) {
      arrayDiffs.push(new ArrayDiffEntry(--i, oldArrayCopy[i], undefined));
    }

    return arrayDiffs;
  };
}

export default angular
  .module('services.helper', [])
  .service('HelperService', HelperService)
  .name;
