import angular from 'angular';
import annotationPopup from './annotationPopup.tpl.html';
import readonlyAnnotationPopup from './readonlyAnnotationPopup.tpl.html';
// @ngInject
function AnnotationPopupService($uibModal) {
  return {
    showDialog,
  };

  function showDialog(apiService, item, stadiumOrderId, businessType, readonly = false) {
    const modalDefaults = {
      backdrop: 'static',
      controller: 'AnnotationPopupController as vm',
      templateUrl: readonly ? readonlyAnnotationPopup : annotationPopup,
      resolve: {
        item: () => item,
        stadiumOrderId: () => stadiumOrderId,
        businessType: () => businessType,
        apiService: () => apiService,
      },
    };

    return $uibModal.open(modalDefaults).result.catch(angular.noop);
  }
}

// @ngInject
function AnnotationPopupController(HelperService, $uibModalInstance, apiService, item, stadiumOrderId, businessType) {
  const vm = this;
  vm.item = item;
  vm.stadiumOrderId = stadiumOrderId;
  vm.businessType = businessType;
  vm.annotation = item.annotation || '';
  vm.getDisplayName = HelperService.getDisplayName;
  vm.alert = null;
  vm.clear = clear;
  vm.cancel = cancel;
  vm.save = save;

  function clear() {
    vm.annotation = '';
  }

  function cancel() {
    $uibModalInstance.dismiss();
  }

  function save() {
    const isDirty = vm.item.annotation !== vm.annotation;
    vm.item.annotation = vm.annotation.trim();

    if (isDirty) {
      apiService.updateAnnotation(vm.stadiumOrderId, item.annotation).then(() => {
        $uibModalInstance.close();
      }, (error) => {
        if (error && error.data && error.data.message) {
          vm.alert = error.data.message;
        }
      });
    }
  }
}

export default angular
  .module('services.annotationPopupService', [])
  .service('AnnotationPopupService', AnnotationPopupService)
  .controller('AnnotationPopupController', AnnotationPopupController)
  .name;
