import angular from 'angular';
import problemPopup from './problemPopup.tpl.html';

// @ngInject
function ProblemPopupService($uibModal, ProblemApiService) {
  return {
    showDialog,
    showReportingDialog,
  };

  function showReportingDialog(orderNumber, stadiumOrderNumber, stadiumProject, orderStatus) {
    const modalDefaults = {
      backdrop: 'static',
      controller: 'ProblemPopupController as vm',
      templateUrl: problemPopup,
      resolve: {
        item: {
          orderNumber,
          stadiumOrderNumber,
          stadiumProject,
          orderStatus,
        },
        problem: () => ProblemApiService.getProblem(orderNumber)
          .catch(() => null),
      },
    };

    return $uibModal.open(modalDefaults).result.catch(angular.noop);
  }

  function showDialog(item, orderNumber) {
    const modalDefaults = {
      backdrop: 'static',
      controller: 'ProblemPopupController as vm',
      templateUrl: problemPopup,
      resolve: {
        item: () => item,
        problem: () => ProblemApiService.getProblem(orderNumber),
      },
    };

    return $uibModal.open(modalDefaults).result.catch(angular.noop);
  }
}

// @ngInject
function ProblemPopupController(
  HelperService,
  $uibModalInstance,
  item,
  problem,
  ProblemApiService,
) {
  const vm = this;
  vm.item = item;
  vm.problem = problem;
  vm.readonly = !!problem;
  vm.reason = vm.readonly ? problem.reason : '';
  vm.getDisplayName = HelperService.getDisplayName;
  vm.alert = null;
  vm.clear = clear;
  vm.cancel = cancel;
  vm.submit = submit;

  function clear() {
    vm.reason = '';
  }

  function cancel() {
    $uibModalInstance.dismiss();
  }

  function submit() {
    ProblemApiService.createProblem(item, vm.reason)
      .then(() => {
        $uibModalInstance.close();
      })
      .catch((error) => {
        if (error && error.data && error.data.message) {
          vm.alert = error.data.message;
        }
      });
  }
}

export default angular
  .module('services.problemPopupService', [])
  .service('ProblemPopupService', ProblemPopupService)
  .controller('ProblemPopupController', ProblemPopupController)
  .name;
