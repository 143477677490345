import enTranslations from '../../../assets/lang/locale-en.json';

// @ngInject
function translationConfig($translateProvider) {
  $translateProvider.translations('en', enTranslations);
  $translateProvider.preferredLanguage('en');
  $translateProvider.useSanitizeValueStrategy('escape');
}

// @ngInject
function angularDataConfig(DSProvider) {
  DSProvider.defaults.baseUrl = process.env.BACKEND_BASE_URL;
}


// @ngInject
function errorLogServiceConfig($provide) {
  $provide.decorator('$exceptionHandler', exceptionHandler);
}

// @ngInject
function exceptionHandler($delegate, ErrorLogService) {
  return function (exception, cause) {
    ErrorLogService.logException(exception, cause);
    $delegate(exception, cause);
  };
}

// @ngInject
function httpProviderConfig($httpProvider) {
  $httpProvider.interceptors.push('httpInterceptor');
  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.common = {};
  }
  $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.common.Pragma = 'no-cache';
}

// @ngInject
function httpInterceptor($injector, $rootScope, $q, ErrorLogService) {
  return {
    request: (config) => {
      const AuthService = $injector.get('AuthService');
      const token = AuthService.getCurrentToken();
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    responseError(rejection) {
      const AuthService = $injector.get('AuthService');
      if (rejection.status === 401) {
        if (AuthService.hasToken()) {
          AuthService.expireSession();
          if ($rootScope.toState !== null) {
            AuthService.showLogin('Your session has expired');
          }
        } else {
          AuthService.showLogin();
        }
      }
      if (rejection.status !== 401 && rejection.status !== 400) {
        ErrorLogService.logException(new Error(`HTTP ${rejection.status} response error`), {
          config: rejection.config,
          status: rejection.status,
        });
      }

      return $q.reject(rejection);
    },
  };
}

// @ngInject
function tooltipConfig($uibTooltipProvider) {
  $uibTooltipProvider.options({
    animation: false,
    popupDelay: 750,
  });
  $uibTooltipProvider.setTriggers({
    openTrigger: 'closeTrigger',
  });
}

export default angular
  .module('app.core.config', [])
  .factory('httpInterceptor', httpInterceptor)
  .config(translationConfig)
  .config(angularDataConfig)
  .config(errorLogServiceConfig)
  .config(tooltipConfig)
  .config(httpProviderConfig)
  .name;
