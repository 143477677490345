// @ngInject
function PickingListApiService(DS, DSHttpAdapter) {
  return {
    get,
  };

  function get(id) {
    return DSHttpAdapter.GET(`${DS.defaults.baseUrl}picking-list/${id}`)
      .then((response) => response.data);
  }
}

export default angular
  .module('resources.pickingList', [])
  .factory('PickingListApiService', PickingListApiService)
  .name;
