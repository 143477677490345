export default class PrintingSummaryApiService {
  // @ngInject
  constructor(DS, DSHttpAdapter) {
    this.DS = DS;
    this.DSHttpAdapter = DSHttpAdapter;
  }

  getPrintingSummary(fromDate, toDate) {
    const url = `${this.DS.defaults.baseUrl}printing-summary`;
    return this.DSHttpAdapter.GET(url, {
      params: {
        fromDate,
        toDate,
      },
    }).then((response) => response.data);
  }
}
