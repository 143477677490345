export default class HeaderController {
  // @ngInject
  constructor(AuthService) {
    this.AuthService = AuthService;
    this.navCollapsed = true;
  }

  hasClaim(claim) {
    return this.AuthService.hasClaim(claim);
  }

  isAuthenticated() {
    return this.AuthService.isAuthenticated();
  }

  signOut() {
    this.AuthService.logOut();
  }

  toggleNav() {
    this.navCollapsed = !this.navCollapsed;
  }
}
