import angular from 'angular';
import core from './core/core';
import main from './main/main';
import login from './login/login';
import print from './print/print';

// @ngInject
function stateConfig($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('', '/');
  $stateProvider.state('app', {
    abstract: true,
    url: '',
    data: { pageTitle: 'Flow' },
  });
}

export default angular
  .module('app', [
    core,
    main,
    login,
    print,
  ])
  .config(stateConfig)
  .name;
