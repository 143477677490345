import angular from 'angular';
import HeaderController from './HeaderController';
import template from './Header.tpl.html';
import './Header.less';

export default angular
  .module('app.main.header', [])
  .component('mainHeader', {
    controller: HeaderController,
    templateUrl: template,
    controllerAs: 'vm',
    bindings: {
      user: '<',
    },
  })
  .name;
