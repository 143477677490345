import angular from 'angular';
import printingQueue from './printingQueue.tpl.html';
import generatePagesArray from '../../../common/services/table/table';

// @ngInject
const stateConfig = ($stateProvider) => {
  $stateProvider.state('app.main.printingQueue', {
    url: '/printing-queue',
    views: {
      'main-content': {
        templateUrl: printingQueue,
        controller: 'PrintingQueueController as vm',
      },
    },
  });
};

// @ngInject
function PrintingQueueController(
  NgTableParams,
  $scope,
  PrintingQueueApiService,
  OrderStatusApiService,
  LoadSpinnerService,
  HelperService,
  AuthService,
  ConfirmDialogService,
  ProblemPopupService,
  $state,
  AnnotationPopupService,
  ProductionItemsApiService
) {
  const vm = this;

  vm.tableParams = {};
  vm.filter = {
    orderNumber: '',
    stadiumOrderNumber: '',
    fromDate: '',
    toDate: '',
  };
  vm.initialized = false;
  vm.getDisplayName = HelperService.getDisplayName;
  vm.hasClaim = AuthService.hasClaim;
  vm.getStatusClass = getStatusClass;
  vm.isStarted = isStarted;
  vm.onClickStartOrStop = onClickStartOrStop;
  vm.onClickComplete = onClickComplete;
  vm.onSearchSubmit = onSearchSubmit;
  vm.onClickSendToProblemQueue = onClickSendToProblemQueue;
  vm.hasAnnotation = hasAnnotation;
  vm.onClickAnnotate = onClickAnnotate;

  initialise();

  function initialise() {
    initialiseTable();
  }

  function initialiseTable() {
    vm.tableParams = new NgTableParams(
      {
        page: 1,
        count: 20,
        sorting: {
          datePrinted: 'desc',
        },
      },
      {
        counts: [],
        defaultSort: 'asc',
        total: 0,
        getData: updateTable,
        generatePagesArray,
      },
    );
  }

  function updateTableData(params) {
    return PrintingQueueApiService.getAll(
      params.sorting(),
      params.page(),
      params.count(),
      vm.filter.orderNumber,
      vm.filter.stadiumOrderNumber,
      vm.filter.fromDate,
      vm.filter.toDate,
    );
  }

  function updateTable(params) {
    LoadSpinnerService.start();
    return updateTableData(params).then((response) => {
      params.total(response.count);
      vm.initialized = true;
      return response.items;
    }).finally(() => {
      LoadSpinnerService.stop();
    });
  }

  function getStatusClass(prefix, item) {
    let { orderStatus } = item;
    orderStatus = orderStatus.replace(new RegExp('_', 'g'), '-').toLowerCase();
    return `${prefix} ${prefix}-${orderStatus}`;
  }

  function onClickStartOrStop(item) {
    const newStatus = item.orderStatus === 'STARTED' ? 'READY_FOR_PRINT' : 'STARTED';
    OrderStatusApiService.updateStatus(item.orderNumber, newStatus)
      .then(() => {
        item.orderStatus = newStatus;
      });
  }

  function onClickComplete(item) {
    const newStatus = 'DONE';
    const buttons = [
      { name: 'BUTTON.NO', class: 'btn-default' },
      { name: 'BUTTON.YES', class: 'btn-success' },
    ];

    ConfirmDialogService.showDialog('CONFIRMPOPUP.COMPLETETITLE', 'CONFIRMPOPUP.MARKASCOMPLETEDMESSAGE', buttons)
      .then((button) => {
        if (button.name === 'BUTTON.YES') {
          OrderStatusApiService.updateStatus(item.orderNumber, newStatus)
            .then(() => {
              $state.reload();
            });
        }
      }).catch(angular.noop);
  }

  function onClickSendToProblemQueue(item) {
    ProblemPopupService.showReportingDialog(
      item.orderNumber,
      item.stadiumOrderNumber,
      item.stadiumProject,
      item.orderStatus,
    )
      .then(() => {
        $state.reload();
      });
  }

  function isStarted(item) {
    return item.orderStatus === 'STARTED';
  }

  function onSearchSubmit(params) {
    vm.filter.orderNumber = params.orderNumber;
    vm.filter.stadiumOrderNumber = params.stadiumOrderNumber;
    vm.filter.fromDate = params.fromDate;
    vm.filter.toDate = params.toDate;
  }

  function onClickAnnotate(item) {
    AnnotationPopupService.showDialog(ProductionItemsApiService, item, item.stadiumOrderNumber, "B2C");
  }

  function hasAnnotation(item) {
    return (item.annotation || '').length > 0;
  }

  $scope.$watch(() => angular.toJson(vm.filter), (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (vm.tableParams.page() !== 1) {
        vm.tableParams.page(1);
      }
      vm.tableParams.reload();
    }
  }, true);
}

export default angular
  .module('app.main.printingQueue', [])
  .controller('PrintingQueueController', PrintingQueueController)
  .config(stateConfig)
  .name;
