import angular from 'angular';
import problemQueue from './problemQueue.tpl.html';
import generatePagesArray from '../../../common/services/table/table';

// @ngInject
const stateConfig = ($stateProvider) => {
  $stateProvider.state('app.main.problemQueue', {
    url: '/problem-queue',
    views: {
      'main-content': {
        templateUrl: problemQueue,
        controller: 'ProblemQueueController as vm',
      },
    },
  });
};

// @ngInject
function ProblemQueueController(
  NgTableParams,
  $scope,
  ProblemQueueApiService,
  OrderStatusApiService,
  LoadSpinnerService,
  HelperService,
  AuthService,
  ConfirmDialogService,
  ProblemPopupService,
  AnnotationPopupService,
  ProductionItemsApiService
) {
  /* jshint validthis: true */
  const vm = this;

  vm.tableParams = {};
  vm.filter = {
    orderNumber: '',
    stadiumOrderNumber: '',
    fromDate: '',
    toDate: '',
  };
  vm.initialized = false;
  vm.getDisplayName = HelperService.getDisplayName;
  vm.hasClaim = AuthService.hasClaim;
  vm.onClickComplete = onClickComplete;
  vm.onClickShowProblem = onClickShowProblem;
  vm.onSearchSubmit = onSearchSubmit;
  vm.hasAnnotation = hasAnnotation;
  vm.onClickAnnotate = onClickAnnotate;

  initialise();

  function initialise() {
    initialiseTable();
  }

  function initialiseTable() {
    /* jshint ignore:start */
    vm.tableParams = new NgTableParams({
      page: 1,
      count: 20,
      sorting: {
        id: 'asc',
      },
    },
    {
      counts: [],
      defaultSort: 'asc',
      total: 0,
      getData: updateTable,
      generatePagesArray,
    });
  }

  function updateTableData(params) {
    return ProblemQueueApiService.getAll(
      params.sorting(),
      params.page(),
      params.count(),
      vm.filter.orderNumber,
      vm.filter.stadiumOrderNumber,
      vm.filter.fromDate,
      vm.filter.toDate,
    );
  }

  function updateTable(params) {
    LoadSpinnerService.start();
    return updateTableData(params)
      .then((response) => {
        params.total(response.count);
        vm.initialized = true;
        return response.items;
      }).finally(() => {
        LoadSpinnerService.stop();
      });
  }

  function onClickComplete(item) {
    const newStatus = 'DONE';
    const buttons = [
      { name: 'BUTTON.NO', class: 'btn-default' },
      { name: 'BUTTON.YES', class: 'btn-success' },
    ];

    ConfirmDialogService.showDialog('CONFIRMPOPUP.COMPLETETITLE', 'CONFIRMPOPUP.MARKASCOMPLETEDMESSAGE', buttons)
      .then((button) => {
        if (button.name === 'BUTTON.YES') {
          OrderStatusApiService.updateStatus(item.orderNumber, newStatus)
            .then(() => {
              item.orderStatus = newStatus;
            });
        }
      }).catch(angular.noop);
  }

  function onClickShowProblem(item) {
    ProblemPopupService.showDialog(item, item.orderNumber);
  }

  function onSearchSubmit(params) {
    vm.filter.orderNumber = params.orderNumber;
    vm.filter.stadiumOrderNumber = params.stadiumOrderNumber;
    vm.filter.fromDate = params.fromDate;
    vm.filter.toDate = params.toDate;
  }

  function onClickAnnotate(item) {
    AnnotationPopupService.showDialog(ProductionItemsApiService, item, item.stadiumOrderNumber, "B2C");
  }

  function hasAnnotation(item) {
    return (item.annotation || '').length > 0;
  }

  $scope.$watch(() => vm.filter, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (vm.tableParams.page() !== 1) {
        vm.tableParams.page(1);
      }
      vm.tableParams.reload();
    }
  }, true);
}

export default angular
  .module('app.main.problemQueue', [])
  .controller('ProblemQueueController', ProblemQueueController)
  .config(stateConfig)
  .name;
