import angular from 'angular';
import template from './pickingQueue.tpl.html';
import './pickingQueue.less';
import generatePagesArray from '../../../common/services/table/table';

// @ngInject
const stateConfig = ($stateProvider) => {
  $stateProvider.state('app.main.pickingQueue', {
    url: '/picking-queue',
    views: {
      'main-content': {
        templateUrl: template,
        controller: 'PickingQueueController as vm',
      },
    },
  });
};

// @ngInject
function PickingQueueController(
  NgTableParams,
  $scope,
  PickingQueueApiService,
  LoadSpinnerService,
  HelperService,
  AuthService,
  AnnotationPopupService,
  ProductionItemsApiService
) {
  const vm = this;
  vm.tableParams = {};
  vm.filter = {
    stadiumOrderNumber: '',
    orderNumber: '',
    fromDate: '',
    toDate: '',
  };
  vm.selectedRows = [];
  vm.initialized = false;
  vm.getDisplayName = HelperService.getDisplayName;
  vm.hasClaim = AuthService.hasClaim;
  vm.onSearchSubmit = onSearchSubmit;
  vm.onRowClicked = onRowClicked;
  vm.isRowSelected = isRowSelected;
  vm.hasAnnotation = hasAnnotation;
  vm.onClickAnnotate = onClickAnnotate;

  initialise();

  function initialise() {
    initialiseTable();
  }

  function initialiseTable() {
    vm.tableParams = new NgTableParams(
      {
        page: 1,
        count: 20,
        sorting: {
          id: 'asc',
        },
      },
      {
        counts: [],
        defaultSort: 'asc',
        total: 0,
        getData: updateTable,
        generatePagesArray,
      },
    );
  }

  function onRowClicked(row) {
    const i = vm.selectedRows.indexOf(row.pickingListId);
    if (i < 0) {
      vm.selectedRows.push(row.pickingListId);
    } else {
      vm.selectedRows.splice(i, 1);
    }
  }

  function isRowSelected(row) {
    return vm.selectedRows.indexOf(row.pickingListId) >= 0;
  }

  function updateTableData(params) {
    return PickingQueueApiService.getAll(
      params.sorting(),
      params.page(),
      params.count(),
      vm.filter.stadiumOrderNumber,
      vm.filter.orderNumber,
      vm.filter.fromDate,
      vm.filter.toDate,
    );
  }

  function updateTable(params) {
    LoadSpinnerService.start();

    return updateTableData(params).then((response) => {
      params.total(response.count);
      vm.initialized = true;
      return response.items;
    }).finally(() => LoadSpinnerService.stop());
  }

  function onSearchSubmit(params) {
    vm.filter.orderNumber = params.orderNumber;
    vm.filter.stadiumOrderNumber = params.stadiumOrderNumber;
    vm.filter.fromDate = params.fromDate;
    vm.filter.toDate = params.toDate;
  }

  function onClickAnnotate(item) {
    AnnotationPopupService.showDialog(ProductionItemsApiService, item, item.stadiumOrderNumber, "B2C");
  }

  function hasAnnotation(item) {
    return (item.annotation || '').length > 0;
  }

  $scope.$watch(() => vm.filter, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (vm.tableParams.page() !== 1) {
        vm.tableParams.page(1);
      }
      vm.tableParams.reload();
    }
  }, true);
}

export default angular
  .module('app.main.pickingQueue', [])
  .controller('PickingQueueController', PickingQueueController)
  .config(stateConfig)
  .name;
