import angular from 'angular';
import errorLog from './errorLog/errorLog';
import helper from './helper/helper';
import security from './security/security';
import confirmDialog from './confirmDialog/confirm';
import editPopup from './editPopup/editPopup';
import previewPopup from './previewPopup/previewPopup';
import annotationPopup from './annotationPopup/annotationPopup';
import problemPopup from './problemPopup/problemPopup';

export default angular
  .module('services', [
    errorLog,
    helper,
    security,
    confirmDialog,
    editPopup,
    previewPopup,
    annotationPopup,
    problemPopup,
  ])
  .name;
