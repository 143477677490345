// @ngInject
function ConfirmDialogService($uibModal) {
  return {
    showDialog,
  };

  function showDialog(title, question, buttons) {
    const modalDefaults = {
      backdrop: 'static',
      controller: 'ConfirmDialogController as vm',
      templateUrl: require('./confirmDialog.tpl.html'),
      resolve: {
        title() {
          return title || {};
        },
        question() {
          return question || {};
        },
        buttons() {
          return buttons || [];
        },
      },
    };

    return $uibModal.open(modalDefaults).result.catch(angular.noop);
  }
}

// @ngInject
function ConfirmDialogController($uibModalInstance, title, question, buttons) {
  const vm = this;
  vm.title = title;
  vm.question = question;
  vm.buttons = buttons;
  vm.onClickButton = onClickButton;
  vm.cancel = cancel;

  function cancel() {
    $uibModalInstance.dismiss();
  }

  function onClickButton(button) {
    $uibModalInstance.close(button);
  }
}

export default angular
  .module('services.confirmDialog', [])
  .service('ConfirmDialogService', ConfirmDialogService)
  .controller('ConfirmDialogController', ConfirmDialogController)
  .name;
