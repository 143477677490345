export default class PrintingSummaryController {
  // @ngInject
  constructor(PrintingSummaryApiService) {
    this.PrintingSummaryApiService = PrintingSummaryApiService;
    this.summaries = [];
    this.currentDate = new Date();
  }

  onSearchClicked() {
    if (!this.toDate || !this.fromDate) {
      return;
    }

    this.toDate.setHours(23, 59, 59, 999);
    this.fromDate.setHours(0, 0, 0, 0);
    this.PrintingSummaryApiService.getPrintingSummary(
      this.fromDate,
      this.toDate,
    ).then((summaries) => {
      this.summaries = summaries;
    });
  }
}
