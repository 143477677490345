import angular from 'angular';

// @ngInject
function PickingQueueApiService(DS, DSHttpAdapter) {
  return {
    getAll,
  };

  function getAll(
    sorting,
    pageNumber,
    pageSize,
    stadiumOrderNumber,
    orderNumber,
    fromDate,
    toDate,
  ) {
    const entries = Object.entries(sorting);
    const sort = entries.map(([key, direction]) => ({ key, direction }));

    const params = {
      from: (pageNumber - 1) * pageSize,
      pageSize,
      sorting: sort,
      stadiumOrderNumber: stadiumOrderNumber || '',
      orderNumber: orderNumber || '',
      fromDate: fromDate || '',
      toDate: toDate || '',
    };

    const url = `${DS.defaults.baseUrl}picking-queue-items/_search`;
    return DSHttpAdapter.POST(url, params)
      .then((response) => response.data);
  }

}

export default angular
  .module('resources.pickingQueue', [])
  .factory('PickingQueueApiService', PickingQueueApiService)
  .name;
