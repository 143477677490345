import angular from 'angular';
import start from './start/start';
import productionItems from './productionItems/productionItems';
import pickingQueue from './pickingQueue/pickingQueue';
import printingQueue from './printingQueue/printingQueue';
import problemQueue from './problemQueue/problemQueue';
import archiveQueue from './archiveQueue/archiveQueue';
import OrderDetailsModule from './orderDetails/OrderDetailsModule';
import template from './main.tpl.html';
import MainComponents from './components/MainComponents';
import HeaderModule from './header/HeaderModule';
import PrintingSummaryModule from './printingSummary/PrintingSummaryModule';

// @ngInject
function stateConfig($stateProvider) {
  $stateProvider.state('app.main', {
    url: '',
    abstract: true,
    views: {
      '@': {
        templateUrl: template,
        controller: 'MainController as vm',
      },
    },
    resolve: {
      user: userResolve,
    },
  });
}

// @ngInject
function userResolve(AuthService) {
  return AuthService.getUser();
}

// @ngInject
function MainController(AuthService, user) {
  /* jshint validthis: true */
  const vm = this;
  vm.hasClaim = AuthService.hasClaim;
  vm.user = user;
}

export default angular
  .module('app.main', [
    start,
    productionItems,
    pickingQueue,
    printingQueue,
    problemQueue,
    archiveQueue,
    OrderDetailsModule,
    MainComponents,
    HeaderModule,
    PrintingSummaryModule,
  ])
  .controller('MainController', MainController)
  .config(stateConfig)
  .name;
