import angular from 'angular';
import archiveQueue from './archiveQueue.tpl.html';
import generatePagesArray from '../../../common/services/table/table';

// @ngInject
const stateConfig = ($stateProvider) => {
  $stateProvider.state('app.main.archiveQueue', {
    url: '/archive-queue',
    views: {
      'main-content': {
        templateUrl: archiveQueue,
        controller: 'ArchiveQueueController as vm',
      },
    },
  });
};

// @ngInject
function ArchiveQueueController(
  NgTableParams,
  $scope,
  ArchiveQueueApiService,
  LoadSpinnerService,
  HelperService,
  AuthService,
  AnnotationPopupService,
  ProductionItemsApiService
) {
  const vm = this;

  vm.tableParams = {};
  vm.filter = {
    orderNumber: '',
    stadiumOrderNumber: '',
    fromDate: '',
    toDate: '',
  };
  vm.initialized = false;
  vm.getDisplayName = HelperService.getDisplayName;
  vm.hasClaim = AuthService.hasClaim;
  vm.onSearchSubmit = onSearchSubmit;
  vm.hasAnnotation = hasAnnotation;
  vm.onClickAnnotate = onClickAnnotate;

  initialise();

  function initialise() {
    initialiseTable();
  }

  function initialiseTable() {
    vm.tableParams = new NgTableParams(
      {
        page: 1,
        count: 100,
        sorting: {
          completedDate: 'desc',
        },
      },
      {
        counts: [],
        defaultSort: 'asc',
        total: 0,
        getData: updateTable,
        generatePagesArray,
      },
    );
  }

  function updateTableData(params) {
    return ArchiveQueueApiService.getAll(
      params.sorting(),
      params.page(),
      params.count(),
      vm.filter.orderNumber,
      vm.filter.stadiumOrderNumber,
      vm.filter.fromDate,
      vm.filter.toDate,
    );
  }

  function updateTable(params) {
    LoadSpinnerService.start();
    return updateTableData(params).then((response) => {
      params.total(response.count);
      vm.initialized = true;
      return response.items;
    }).finally(() => {
      LoadSpinnerService.stop();
    });
  }

  function onSearchSubmit(params) {
    vm.filter.orderNumber = params.orderNumber;
    vm.filter.stadiumOrderNumber = params.stadiumOrderNumber;
    vm.filter.fromDate = params.fromDate;
    vm.filter.toDate = params.toDate;
  }

  function onClickAnnotate(item) {
    AnnotationPopupService.showDialog(ProductionItemsApiService, item, item.stadiumOrderNumber, "B2C");
  }

  function hasAnnotation(item) {
    return (item.annotation || '').length > 0;
  }

  $scope.$watch(() => angular.toJson(vm.filter), (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (vm.tableParams.page() !== 1) {
        vm.tableParams.page(1);
      }
      vm.tableParams.reload();
    }
  }, true);
}

export default angular
  .module('app.main.archiveQueue', [])
  .controller('ArchiveQueueController', ArchiveQueueController)
  .config(stateConfig)
  .name;
