// @ngInject
function EditPopupService($uibModal) {
  return {
    showDialog,
  };

  function showDialog(apiService, item) {
    const modalDefaults = {
      backdrop: 'static',
      controller: 'EditPopupController as vm',
      templateUrl: require('../../../common/services/editPopup/editPopup.tpl.html'),
      resolve: {
        item: () => item,
        apiService: () => apiService,
      },
    };

    return $uibModal.open(modalDefaults).result.catch(angular.noop);
  }
}

// @ngInject
function EditPopupController($uibModalInstance, $window, item, apiService) {
  const vm = this;
  vm.item = item;
  vm.stadiumReference = item.stadiumReference || '';
  vm.alert = null;
  vm.close = close;
  vm.save = save;

  function save() {
    const isDirty = vm.item !== vm.stadiumReference;
    vm.item.newReference = vm.stadiumReference.trim();

    if (isDirty) {
      apiService.updateStadiumReference(item.stadiumReference, item.newReference).then(() => {
        $uibModalInstance.close();
        $window.location.reload();
      }, (error) => {
        if (error && error.data && error.data.message) {
          vm.alert = error.data.message;
        }
      });
    }
  }

  function close() {
    $uibModalInstance.dismiss();
  }
}

export default angular
  .module('services.editPopupService', [])
  .service('EditPopupService', EditPopupService)
  .controller('EditPopupController', EditPopupController)
  .name;
