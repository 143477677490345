export default class OrderDetailsController {
  // @ngInject
  constructor(
    orderDetails,
    OrderDetailsApiService,
    OrderStatusApiService,
    ProblemPopupService,
    ProblemApiService,
    $sce,
    $state,
    $window
  ) {
    this.details = orderDetails;
    this.OrderDetailsApiService = OrderDetailsApiService;
    this.OrderStatusApiService = OrderStatusApiService;
    this.ProblemPopupService = ProblemPopupService;
    this.ProblemApiService = ProblemApiService;
    this.$sce = $sce;
    this.$state = $state;
    this.isOrderDetailsVisible = false;
    this.loadingRow = true;
    this.$window = $window;
  }


  onOrderRowClicked(row) {
    this.selectedRow = row;
    this.loadingRow = true;
    this.OrderDetailsApiService.getOrderRowDetails(row.id)
      .then((rowDetails) => {
        this.selectedRow = rowDetails;
        this.loadingRow = false;
      });
  }

  startOrder() {
    this.OrderStatusApiService.updateStatus(this.details.orderNumber, 'STARTED')
      .then(() => {
        this.details.orderStatus = 'STARTED';
      });
  }

  stopOrder() {
    this.OrderStatusApiService.updateStatus(this.details.orderNumber, 'READY_FOR_PRINT')
      .then(() => {
        this.details.orderStatus = 'READY_FOR_PRINT';
      });
  }

  finishOrder() {
    this.OrderStatusApiService.updateStatus(this.details.orderNumber, 'DONE')
      .then(() => {
        this.details.orderStatus = 'DONE';
      });
  }

  reportOrder() {
    this.ProblemPopupService.showReportingDialog(
      this.details.orderNumber,
      this.details.stadiumOrderNumber,
      this.details.stadiumProject,
      this.details.orderStatus,
    ).then(() => {
      this.$state.reload();
    });
  }

  resolveOrder() {
    this.ProblemApiService.resolveProblem(this.details.orderNumber)
      .then((newStatus) => {
        this.details.orderStatus = newStatus;
      });
  }

  toggleNameDone(name) {
    if (!this.loadingRow) {
      name.templateId = this.selectedRow.templateId;
      name.done = !name.done;
      this.OrderDetailsApiService.updateName(name);  
    }
  }

  getIframeUrl(perspective) {
    var halfScreenWidth = this.$window.innerWidth/2;
    var templateUrl = perspective.templateUrl + "/";
    if (halfScreenWidth < 600 && this.selectedRow.perspectives.length > 1) {
      templateUrl += halfScreenWidth + "x" + halfScreenWidth;
    }
    return this.$sce.trustAsResourceUrl(templateUrl);
  }

  toggleOrderDetailsVisible() {
    this.isOrderDetailsVisible = !this.isOrderDetailsVisible;
  }
}
