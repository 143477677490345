import rg4js from 'raygun4js';

class ErrorLogService {
  // @ngInject
  constructor($injector) {
    this.$injector = $injector;
  }

  init(options) {
    rg4js('enableCrashReporting', true);
    rg4js('apiKey', options.apiKey);
    rg4js('setVersion', options.version);
    rg4js('withTags', ['frontend']);
  }

  logException(exception, cause) {
    const state = this.$injector.get('$state');
    rg4js('send', {
      error: exception,
      customData: [{
        cause,
        state: state.current,
      }],
    });
  }

  setUser(user) {
    if (user === null) {
      const instance = rg4js('getRaygunInstance');
      if (instance) {
        instance.resetAnonymousUser();
      }
    } else {
      rg4js('setUser', {
        email: user.email,
        isAnonymous: false,
      });
    }
  }
}

export default angular
  .module('services.errorlog', [])
  .service('ErrorLogService', ErrorLogService)
  .name;
