import angular from 'angular';
import OrderFilterController from './OrderFilterController';
import template from './OrderFilter.tpl.html';

export default angular
  .module('app.main.components.orderFilter', [])
  .component('orderFilter', {
    controller: OrderFilterController,
    templateUrl: template,
    bindings: {
      onSearch: '&',
    },
  })
  .name;
