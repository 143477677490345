
// @ngInject
function stateConfig($stateProvider) {
  $stateProvider.state('app.main.start', {
    url: '/',
    views: {
      'main-content': {
        templateUrl: require('./start.tpl.html'),
        controller: 'StartController as vm',
      },
    },
  });
}

// @ngInject
function StartController() {
  /* jshint validthis: true */
  const vm = this;
}

export default angular
  .module('app.main.start', [])
  .controller('StartController', StartController)
  .config(stateConfig)
  .name;
