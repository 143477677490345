import angular from 'angular';
import template from './OrderDetails.tpl.html';
import OrderDetailsController from './OrderDetailsController';
import './orderDetails.less';

const orderDetailsResolve = ($stateParams, OrderDetailsApiService) => (
  OrderDetailsApiService.getOrderDetails($stateParams.productionItemId)
);

const stateConfig = ($stateProvider) => {
  $stateProvider.state('app.main.orderDetails', {
    url: '/order-details/{productionItemId}',
    views: {
      'main-content': {
        templateUrl: template,
        controller: OrderDetailsController,
        controllerAs: 'vm',
      },
    },
    resolve: {
      orderDetails: orderDetailsResolve,
    },
  });
};

const OrderDetailsModule = angular
  .module('app.main.orderDetails', [])
  .config(stateConfig)
  .name;

export default OrderDetailsModule;
