// @ngInject
function PreviewPopupService($uibModal) {
  return {
    showDialog,
  };

  function getItemRows(apiService, item) {
    return apiService.getByStadiumReference(item.stadiumReference);
  }

  function showDialog(apiService, item) {
    const modalDefaults = {
      backdrop: 'static',
      size: 'lg',
      controller: 'PreviewPopupController as vm',
      templateUrl: require('../../../common/services/previewPopup/previewPopup.tpl.html'),
      resolve: {
        item: () => item,
        rows: () => getItemRows(apiService, item),
      },
    };

    return $uibModal.open(modalDefaults).result.catch(angular.noop);
  }
}

// @ngInject
function PreviewPopupController(HelperService, $uibModalInstance, $sce, $scope, DS, item, rows) {
  const vm = this;
  vm.item = item;
  vm.rows = rows;
  vm.contentUrl = null;
  vm.selectedRow = null;
  vm.getDisplayName = HelperService.getDisplayName;
  vm.close = close;

  function updateUrl() {
    let url = `${DS.defaults.baseUrl}content/`;
    url = `${url + vm.item.stadiumReference}/${vm.selectedRow.id}`;
    url = $sce.trustAsResourceUrl(url);
    vm.contentUrl = url;
  }

  function close() {
    $uibModalInstance.dismiss();
  }

  $scope.$watch(() => vm.selectedRow, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      updateUrl();
    }
  }, true);
}

export default angular
  .module('app.main.productionItems.previewPopupService', [])
  .service('PreviewPopupService', PreviewPopupService)
  .controller('PreviewPopupController', PreviewPopupController)
  .name;
