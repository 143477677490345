export default class OrderDetailsApiService {
  // @ngInject
  constructor(DS, DSHttpAdapter) {
    this.DS = DS;
    this.$http = DSHttpAdapter;
  }

  getOrderDetails(productionItemId) {
    const url = `${this.DS.defaults.baseUrl}order-details/${productionItemId}`;
    return this.$http.GET(url).then((response) => response.data);
  }

  getOrderRowDetails(rowId) {
    const url = `${this.DS.defaults.baseUrl}order-details/rows/${rowId}`;
    return this.$http.GET(url).then((response) => response.data);
  }

  updateName(name) {
    const url = `${this.DS.defaults.baseUrl}order-details/names`;
    return this.$http.PUT(url, name).then((response) => response.data);
  }
}
