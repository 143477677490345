
// @ngInject
function userBox(AuthService) {
  return {
    templateUrl: require('./userBox.tpl.html'),
    restrict: 'EA',
    replace: true,
    scope: true,
    link($scope) {
      $scope.user = {};
      $scope.isAuthenticated = AuthService.isAuthenticated;
      $scope.logOut = AuthService.logOut;
      AuthService.getUser().then((user) => {
        $scope.user = user;
      });
    },
  };
}

export default angular
  .module('directives.userbox', [])
  .directive('userBox', userBox)
  .name;
