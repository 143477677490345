import angular from 'angular';

// @ngInject
function OrderStatusApiService(DS, DSHttpAdapter) {
  return {
    updateStatus,
  };

  function updateStatus(orderNumber, orderStatus) {
    return DSHttpAdapter.POST(`${DS.defaults.baseUrl}order-status`, { orderNumber, orderStatus });
  }
}

export default angular
  .module('resources.orderStatus', [])
  .factory('OrderStatusApiService', OrderStatusApiService)
  .name;
