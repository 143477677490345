import angular from 'angular';
import template from './PrintingSummary.tpl.html';
import PrintingSummaryController from './PrintingSummaryController';
import './PrintingSummary.less';

const stateConfig = ($stateProvider) => {
  $stateProvider.state('app.main.printingSummary', {
    url: '/printing-summary',
    views: {
      'main-content': {
        templateUrl: template,
        controller: PrintingSummaryController,
        controllerAs: 'vm',
      },
    },
  });
};

export default angular
  .module('app.main.printingSummry', [])
  .config(stateConfig)
  .name;
