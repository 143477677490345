import angular from 'angular';
import pickingListPrintView from './pickingListPrintView.tpl.html';

// @ngInject
function stateConfig($stateProvider) {
  $stateProvider
    .state({
      name: 'app.print.picking-list',
      url: '/picking-list?id',
      component: 'pickingListPrint',
      resolve: {
        pickingLists($q, PickingListApiService, $stateParams) {
          const ids = Array.isArray($stateParams.id)
            ? $stateParams.id
            : [$stateParams.id];

          const promises = ids
            .map((id) => PickingListApiService.get(id));

          return $q.all(promises);
        },
      },
    })
    .state({
      name: 'app.print.picking-list.print',
      url: '/print',
      component: 'pickingListPrint',
    });
}

// @ngInject
function PickingListPrintController($q, $timeout, $window, $state, OrderStatusApiService) {
  const vm = this;

  if ($state.current.name === 'app.print.picking-list.print') {
    $timeout(() => $window.print(), 500)
      .finally(() => {
        const promises = vm.pickingLists
          .map((pickingList) => OrderStatusApiService.updateStatus(
            pickingList.orderNumber,
            'READY_FOR_PRINT',
          ));
        return $q.all(promises);
      });
  }
}

export default angular
  .module('app.print.pickingListPrint', [])
  .component('pickingListPrint', {
    bindings: {
      pickingLists: '<',
    },
    controller: PickingListPrintController,
    controllerAs: 'vm',
    templateUrl: pickingListPrintView,
  })
  .config(stateConfig)
  .name;
