import generatePagesArray from '../../../common/services/table/table';

// @ngInject
const stateConfig = ($stateProvider) => {
  $stateProvider.state('app.main.production', {
    url: '/production/{printStatus}',
    views: {
      'main-content': {
        templateUrl: require('./productionItems.tpl.html'),
        controller: 'ProductionItemsController as vm',
      },
    },
    resolve: {
      stores: storesResolve,
      services: servicesResolve,
    },
  });
};

// @ngInject
function storesResolve(MetadataApiService) {
  return MetadataApiService.getStores();
}

// @ngInject
function servicesResolve(ProductionItemsApiService, LoadSpinnerService, HelperService, AuthService) {
  return {
    ProductionItemsApiService,
    LoadSpinnerService,
    HelperService,
    AuthService,
  };
}

// @ngInject
function ProductionItemsController(NgTableParams, $scope, $stateParams, $filter, $window, DS, stores, services, ConfirmDialogService, EditPopupService, PreviewPopupService, AnnotationPopupService) {
  /* jshint validthis: true */
  const vm = this;
  let sortBy = {};

  vm.pageSizes = [];
  vm.tableParams = {};
  vm.filter = {};
  vm.initialized = false;
  vm.printStatus = $stateParams.printStatus;
  vm.selectedRows = 0;
  vm.stores = stores;
  vm.getDisplayName = services.HelperService.getDisplayName;
  vm.hasClaim = services.AuthService.hasClaim;
  vm.getPageNumbers = getPageNumbers;
  vm.getStatusClass = getStatusClass;
  vm.isPrintButtonVisible = isPrintButtonVisible;
  vm.hasAnnotation = hasAnnotation;
  vm.onClickAnnotate = onClickAnnotate;
  vm.onClickPrint = onClickPrint;
  vm.onClickPreview = onClickPreview;
  vm.onClickRemove = onClickRemove;
  vm.onClickRow = onClickRow;
  vm.onClickClear = onClickClear;
  vm.onClickEdit = onClickEdit;
  vm.onClickMarkAsPrinted = onClickMarkAsPrinted;
  vm.isPrinted = isPrinted;
  vm.onClickReset = onClickReset;

  initialise();

  function initialise() {
    initialiseFilter();
    initialiseTable();
  }

  function initialiseTable() {
    const savedState = services.ProductionItemsApiService.getState();
    /* jshint ignore:start */
    vm.tableParams = new NgTableParams({
      page: 1,
      count: vm.pageSizes[0],
      sorting: savedState.sorting || {
        id: 'asc',
      },
    },
    {
      counts: [],
      defaultSort: 'asc',
      total: 0,
      generatePagesArray,
      getData: updateTable,
    });
    /* jshint ignore:end */
  }

  function initialiseFilter() {
    const savedState = services.ProductionItemsApiService.getState();
    vm.pageSizes = [20, 50, 100];
    vm.filter = {
      stadiumReference: savedState.stadiumReference || '',
      bamatexReference: savedState.bamatexReference || '',
      store: null,
      deliveryStatus: savedState.deliveryStatus || null,
      pageSize: savedState.pageSize || vm.pageSizes[0],
    };
    for (const store in vm.stores) {
      vm.filter.store = (savedState.storeId !== vm.stores[store].key) ? vm.filter.store : vm.stores[store];
    }
  }

  function onClickAnnotate(item) {
    AnnotationPopupService.showDialog(services.ProductionItemsApiService, item, item.stadiumReference, "B2B");
  }

  function onClickEdit(item) {
    EditPopupService.showDialog(services.ProductionItemsApiService, item);
  }

  function onClickPreview(item) {
    PreviewPopupService.showDialog(services.ProductionItemsApiService, item);
  }

  function onClickPrint() {
    if (vm.printStatus === 'pending') {
      const buttons = [{ name: 'BUTTON.NO', class: 'btn-default' }, { name: 'BUTTON.YES', class: 'btn-success' }];
      ConfirmDialogService.showDialog('CONFIRMPOPUP.PRINTTITLE', 'CONFIRMPOPUP.PRINTMESSAGE', buttons)
        .then((button) => {
          if (button.name === 'BUTTON.YES') {
            print();
          }
        }).catch(angular.noop);
    } else {
      print();
    }
  }

  function onClickMarkAsPrinted() {
    const buttons = [{ name: 'BUTTON.NO', class: 'btn-default' }, { name: 'BUTTON.YES', class: 'btn-success' }];
    ConfirmDialogService.showDialog('CONFIRMPOPUP.MARKASPRINTEDTITLE', 'CONFIRMPOPUP.MARKASPRINTEDMESSAGE', buttons)
      .then((button) => {
        if (button.name === 'BUTTON.YES') {
          const selectedItems = getSelectedReferences();
          updatePrintStatus(selectedItems);
        }
      }).catch(angular.noop);
  }

  function updatePrintStatus(selectedItems) {
    services.ProductionItemsApiService.updatePrintStatus(selectedItems).then(() => {
      vm.tableParams.reload();
      vm.selectedRows = 0;
    });
  }

  function onClickRemove(row) {
    const buttons = [{ name: 'BUTTON.NO', class: 'btn-default' }, { name: 'BUTTON.YES', class: 'btn-success' }];
    ConfirmDialogService.showDialog('CONFIRMPOPUP.ORDERTITLE', 'CONFIRMPOPUP.ORDERMESSAGE', buttons)
      .then((button) => {
        if (button.name === 'BUTTON.YES') {
          services.ProductionItemsApiService.setArchived(row.stadiumReference).then(() => {
            vm.tableParams.reload();
          });
        }
      }).catch(angular.noop);
  }

  function print() {
    const selectedItems = getSelectedReferences();
    const url = getPrintUrl(selectedItems);
    $window.open(url, '_blank');
    updatePrintStatus(selectedItems);
  }

  function onClickClear() {
    services.ProductionItemsApiService.clearState();
    initialiseFilter();
  }

  function onClickRow(item) {
    if (item.printable) {
      vm.selectedRows = item.selected ? --vm.selectedRows : ++vm.selectedRows;
      item.selected = !item.selected;
    }
  }

  function isPrintButtonVisible() {
    return vm.printStatus === 'ready';
  }

  function hasAnnotation(item) {
    return (item.annotation || '').length > 0;
  }

  function isPrinted() {
    return vm.printStatus === 'printed';
  }

  function getStatusClass(prefix, item) {
    let { deliveryStatus } = item;
    deliveryStatus = deliveryStatus.replace('_', '-').toLowerCase();
    return `${prefix} ${prefix}-${deliveryStatus}`;
  }

  function getPageNumbers(prefix, text1, text2) {
    const pageNumber = vm.tableParams.page();
    const pageCount = Math.ceil(vm.tableParams.total() / vm.filter.pageSize);
    let result = `${getTranslatedText(prefix, text1)} ${Math.max(1, pageNumber)}`;
    result += ` ${getTranslatedText(prefix, text2)} ${Math.max(1, pageCount)}`;
    return result;
  }

  function getTranslatedText(prefix, text) {
    return $filter('translate')(prefix + text);
  }

  function getSelectedReferences() {
    const selectedItems = [];
    for (const index in vm.tableParams.data) {
      const item = vm.tableParams.data[index];
      if (item.selected) {
        selectedItems.push(item.stadiumReference);
      }
    }
    return selectedItems;
  }

  function getPrintUrl(selectedItems) {
    let url = `${DS.defaults.baseUrl}content?`;
    for (const index in selectedItems) {
      url = `${url}id=${selectedItems[index]}&`;
    }
    return url.substring(0, url.length - 1);
  }

  function updateTableData(params) {
    return services.ProductionItemsApiService.getAll(
      params.sorting(),
      params.page(),
      vm.filter.pageSize,
      vm.printStatus,
      vm.filter.deliveryStatus,
      vm.filter.stadiumReference,
      vm.filter.bamatexReference,
      (vm.filter.store || {}).key,
    );
  }

  function updateTable(params) {
    services.LoadSpinnerService.start();
    if (!_.isEqual(sortBy, params.sorting())) {
      sortBy = params.sorting();
      if (params.page() !== 1) {
        params.page(1);
      }
    }

    return updateTableData(params).then((response) => {
      params.total(response.count);
      if (!vm.initialized) {
        vm.initialized = true;
      }
      return response.items;
    }).finally(() => {
      services.LoadSpinnerService.stop();
    });
  }

  function onClickReset(item) {
    const buttons = [{ name: 'BUTTON.NO', class: 'btn-default' }, { name: 'BUTTON.YES', class: 'btn-success' }];
    ConfirmDialogService.showDialog('CONFIRMPOPUP.RESETDELIVERYTITLE', 'CONFIRMPOPUP.RESETDELIVERYSTATUSMESSAGE', buttons).then((button) => {
      if (button.name === 'BUTTON.YES') {
        services.ProductionItemsApiService.resetDeliveryStatus(item.stadiumReference).then(() => {
          vm.tableParams.reload();
        });
      }
    });
  }

  $scope.$watch(() => angular.toJson(vm.filter), (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (vm.tableParams.page() !== 1) {
        vm.tableParams.page(1);
      }
      if (vm.tableParams.count() !== vm.filter.pageSize) {
        vm.tableParams.count(vm.filter.pageSize);
      }
      vm.tableParams.reload();
    }
  }, true);
}

export default angular
  .module('app.main.productionItems', [])
  .controller('ProductionItemsController', ProductionItemsController)
  .config(stateConfig)
  .name;
