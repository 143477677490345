
function LoginDialogController($scope, AuthService, message) {
  /* jshint validthis: true */
  const vm = this;

  vm.user = {};
  vm.authError = null;
  vm.authReason = message;
  vm.isOk = false;
  vm.AuthService = AuthService;
  vm.login = login;
  vm.cancelLogin = cancelLogin;

  function login() {
    // Try to login
    AuthService.logIn(vm.user.email, vm.user.password).then((loggedIn) => {
      vm.isOk = true;
    }, (error) => {
      // If we got an error
      if (error.status === 0 || error.status === 404) {
        vm.authError = 'No connection';
      }

      if (error.data.message) {
        vm.authError = error.data.message;
      }
    });
  }

  function cancelLogin() {
    AuthService.closeLogin();
  }
}

export default angular
  .module('services.security.loginDialog', [])
  .controller('LoginDialogController', LoginDialogController)
  .name;
