export default class ProblemApiService {
  // @ngInject
  constructor(DS, DSHttpAdapter) {
    this.DS = DS;
    this.DSHttpAdapter = DSHttpAdapter;
  }

  createProblem(item, reason) {
    const url = `${this.DS.defaults.baseUrl}problem`;
    return this.DSHttpAdapter.POST(url, {
      orderNumber: item.orderNumber,
      productionItemId: item.id,
      reason,
      orderStatus: item.orderStatus,
    });
  }

  getProblem(orderNumber) {
    const url = `${this.DS.defaults.baseUrl}problem/${orderNumber}`;
    return this.DSHttpAdapter.GET(url)
      .then((response) => response.data);
  }

  resolveProblem(orderNumber) {
    const url = `${this.DS.defaults.baseUrl}problem/${orderNumber}/resolve`;
    return this.DSHttpAdapter.POST(url)
      .then((response) => response.data);
  }
}
